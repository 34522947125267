import React from 'react';
import Layout from '../../components/Shared/Layout';
import ArtDevelopment from '../../components/DetailServices/artdev';
import '../../assets/css/services.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;
// imprt 'bootstrap';

class ArtPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Services/2D3D-Art',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <ArtDevelopment />
      </Layout>
    );
  }
}

export default ArtPage;

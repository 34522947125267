import React from 'react';
import Background from '../../assets/images/services/2d-3d-art/topbanner-bg.jpg';

require('./detailservices.css');

class Iot extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <div>
          <section
            className="service-individual-page content-section-header"
            style={{backgroundImage: `url(${Background})`}}
          >
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <h1 className="latoBlack text-left text-white heading">2D/3D Game Art</h1>
                  <p className="latoRegular text-left text-white description">
                    We specialise in creating memorable characters and amazing game-play experiences
                    in 2D and 3D.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="services-skills mt-5">
            <div className="container justify-content-center align-items-center">
              <div className="row ">
                <div className="col-12 ">
                  <div className="mb-4 ">
                    <h1 className="latoBlack text-left heading">Skill Set</h1>
                    <p className="latoRegular text-left description">
                      We have put together a team of diversified and skilful individuals to produce
                      creative and authentic art designs.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/2d-concept-art.svg')
                              .default
                          }
                          alt="2D Concept Art"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          2D
                          <br />
                          Concept Art
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/line-art.svg')
                              .default
                          }
                          alt="Line Art"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Line
                          <br />
                          Art
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/2d-&-3d-rendering.svg')
                              .default
                          }
                          alt="2D & 3D Rendering"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          2D &amp; 3D
                          <br />
                          Rendering
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/vfx.svg')
                              .default
                          }
                          alt="Vfx"
                        />
                        <p className="text-center latoRegular icon-descriptions">Vfx</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/3d-texturing.svg')
                              .default
                          }
                          alt="3D Texturing"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          3D
                          <br />
                          Texturing
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/rigging.svg')
                              .default
                          }
                          alt="Rigging"
                        />
                        <p className="text-center latoRegular icon-descriptions">Rigging</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/2d-3d-g-animation.svg')
                              .default
                          }
                          alt="2D-3D G-Animation.svg"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          2D &amp; 3D
                          <br />
                          Animation
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/graphic-user-interface.svg')
                              .default
                          }
                          alt="Graphic  User Interface"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Graphic
                          <br />
                          User Interface
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/scene-lighting.svg')
                              .default
                          }
                          alt="Scene Lighting.svg"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Scene
                          <br />
                          Lighting
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/2d-3d-art/skillset/unity-particle-system.svg')
                              .default
                          }
                          alt="Unity Particle System.svg"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Unity
                          <br />
                          Particle System
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <h1 className="latoBlack text-left heading">Tool Set</h1>
                    <p className="latoRegular text-left description">
                      Our artists proficiently use all industrially aligned tool sets, and
                      efficiently work to create market competitive, high quality designs and
                      animations as per the client needs.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/adobe-photoshop.png')
                              .default
                          }
                          alt="Adobe Photoshop"
                        />
                        <p className="text-center latoRegular icon-descriptions">Adobe Photoshop</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/adobe-illustrator.png')
                              .default
                          }
                          alt="Adobe Illustrator"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Adobe Illustrator
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/adobe-after-effects.png')
                              .default
                          }
                          alt="Adobe After Effects"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Adobe After Effects
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/adobe-animate.png')
                              .default
                          }
                          alt="Adobe Animate"
                        />
                        <p className="text-center latoRegular icon-descriptions">Adobe Animate</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/3d-max.png')
                              .default
                          }
                          alt="3D Max"
                        />
                        <p className="text-center latoRegular icon-descriptions">3D Max</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/maya-3d.png')
                              .default
                          }
                          alt="Maya 3D"
                        />
                        <p className="text-center latoRegular icon-descriptions">Maya 3D</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/spine.png')
                              .default
                          }
                          alt="Spine"
                        />
                        <p className="text-center latoRegular icon-descriptions">Spine</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/2d-3d-art/toolset/sketch.png')
                              .default
                          }
                          alt="Sketch"
                        />
                        <p className="text-center latoRegular icon-descriptions">Sketch</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 mb-5">
                    <h1 className="latoBlack text-left heading-portfolio">Portfolio</h1>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100">
                          <img
                            className="w-100 img-fluid"
                            src={
                              require('../../assets/images/services/projects/beat-bugs-service.png')
                                .default
                            }
                            alt="embr"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/us/app/beat-bugs-sing-along/id1214814153?mt=8"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/app-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">App store</p>
                              </a>
                              <a href="/portfolio/beatbugs">
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/website-link.png').default
                                  }
                                />
                                <p className="description text-white text-center">Case Study</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100">
                          <img
                            className="w-100 img-fluid"
                            src={
                              require('../../assets/images/services/projects/care-carts-service.png')
                                .default
                            }
                            alt="care-carts-service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/us/app/care-bears-care-karts/id1138678709?mt=8"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/app-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">App store</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100">
                          <img
                            className="img-fluid w-100"
                            src={
                              require('../../assets/images/services/projects/stick-sports-service.png')
                                .default
                            }
                            alt="Stick Sports-Service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/ky/app/stick-cricket-super-league/id1052018766?mt=8"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/app-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">App store</p>
                              </a>
                              <a href="https://play.google.com/store/apps/details?id=com.sticksports.spl2&hl=en_US">
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/play-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">Play store</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Iot;
